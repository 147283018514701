<template>
  <section>
    <section class="navBack">
      <v-container class="container-custom">
        <v-row>
          <v-col md="12" class="topNav">
            <ul class="navList">
              <li>Home</li>
              <li><v-icon> mdi-chevron-right </v-icon> Change Password</li>
            </ul>
          </v-col>
        </v-row>
      </v-container>
    </section>
    <section class="my-5 mb-15">
      <v-container class="container-custom">
        <v-form>
          <v-row>
            <v-col md="3">
              <Navigation />
            </v-col>
            <v-col md="9" cols="12">
              <h2 class="mb-5">Change Password</h2>
              <div class="filterationBox">
                <v-text-field
                  outlined
                  placeholder="Current Password"
                  v-model="oldPassword"
                  type="password"
                  autocomplete="chrome-off"
                ></v-text-field>
                <v-text-field
                  outlined
                  placeholder="Password"
                  v-model="newPassword"
                  type="password"
                  autocomplete="chrome-off"
                ></v-text-field>
                <p>
                  Include a minimum of 8 characters and at least one number and
                  one letter. No spaces, please.
                </p>
                <v-text-field
                  @keyup="typing"
                  outlined
                  placeholder="Confirm Password"
                  v-model="confirmNewPassword"
                  type="password"
                  autocomplete="chrome-off"
                ></v-text-field>
                <div class="errorMessage">
                  <small v-if="errorMessage">
                    {{ errorMessage }}
                  </small>
                </div>

                <v-btn @click="updatePassword" v-if="!loading"> Save </v-btn>
                <v-btn loading v-if="loading"> </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
    </section>
    <v-snackbar v-model="snackbar" :color="color"> {{ message }}</v-snackbar>
  </section>
</template>

<script>
import Navigation from "@/components/user/Navigation";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "ChangePassword",
  data: () => ({
    oldPassword: undefined,
    newPassword: undefined,
    confirmNewPassword: undefined,
    loading: false,
    snackbar: false,
    errorMessage: undefined,
    message: undefined,
    color: undefined,
  }),
  components: {
    Navigation,
  },
  computed: {
    ...mapGetters(["allPassword"]),
  },
  methods: {
    ...mapActions(["putPassword"]),
    typing() {
      if (this.newPassword != this.confirmNewPassword) {
        this.errorMessage =
          "New password and Confirm Password has to be matched";
      } else {
        this.errorMessage = undefined;
      }
    },
    async updatePassword() {
      this.loading = true;
      let data = {};
      data.oldPassword = this.oldPassword;
      data.newPassword = this.newPassword;
      await this.putPassword(data);
      this.loading = false;
      if (this.allPassword.status == 200) {
        this.snackbar = true;
        this.color = "green";
        this.message = this.allPassword.data.message;
      } else {
        this.snackbar = true;
        this.message = this.allPassword.data.message;
        this.color = "red";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/global.scss";
.navBack {
  background: #f4f4f4;
  padding: 10px 0;
}
.navList {
  padding-left: 0;
  list-style: none;
  display: flex;
  font-size: 14px;
  li {
    .v-icon {
      font-size: 18px;
      margin-left: 5px;
    }
    // margin-right: 15px;
  }
}
.errorMessage {
  margin-top: -20px;
  margin-bottom: 10px;
  color: red;
}
.v-btn {
  min-height: 50px;
  margin-bottom: 15px;
  width: 200px;
  color: #fff !important;
  background: #0878d4 !important;
  box-shadow: none !important;
}
.filterationBox {
  border: 1px solid #efefef;
  padding: 20px 20px 10px;
  margin-bottom: 20px;
  header {
    display: flex;
    margin-bottom: 10px;
    justify-content: space-between;
    font-weight: 500;
  }
  .toogleBox {
    display: none;
  }
  .filterBox {
    padding-left: 0;
    margin-bottom: 0;
    margin-top: 15px;
    li {
      font-size: 14px;
      display: flex;
      align-items: center;
      margin-bottom: 12px;
      a {
        text-decoration: none;
        color: #000;
      }
    }
  }
  .detailList {
    padding-left: 0;
    margin-bottom: 10px;
    li {
      color: #8c8c8c;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 15px 0;
      .v-icon {
        margin-left: 10px;
        font-size: 20px;
      }
    }
  }
}
.v-input {
  margin-top: 0 !important;
}
</style>